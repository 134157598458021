/* ------ */
/* COMPTE */
/* ------ */

body#compte {

    >main.container {

        >section {

            // HISTORIQUE TÉLÉCHARGEMENTS
            &#historique {
                z-index: 99999;
                width: 100%;
                height: 100%;
                overflow-y: auto;
                background-color: rgba(0, 0, 0, 0.4);
                position: fixed;
                top: 0;
                left: 0;

                >article.modal-content {
                    position: relative;
                    background-color: #fefefe;
                    margin: 5vh auto;
                    padding: 0;
                    border: 1px solid #888;
                    width: 80%;
                    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                    animation-name: animateTopLogin;
                    animation-duration: 0.4s;

                    >div {
                        &.modal-header {
                            justify-content: center;
                            padding: 2px 16px;
                            color: white;

                            >h3 {
                                font-weight: bold;
                                font-size: 1.5rem;
                                padding-top: 1rem;
                                padding-bottom: .5rem;
                            }

                            >i {
                                position: absolute;
                                right: 6px;
                                top: 6px;
                                font-size: 1.5rem;
                                color: #dc3545;
                                cursor: pointer;

                                &:hover {
                                    font-weight: normal;
                                }
                            }
                        }

                        &.modal-body {
                            padding: 0 24px 24px 24px;

                            >p {
                                // Nbr logiciels dl.
                                &:first-of-type {
                                    margin-top: 1rem;
                                    margin-bottom: 1.25rem;
                                }
                                // Infos table
                                &:nth-of-type(2){
                                    margin-top: .25rem;
                                    margin-bottom: .5rem;
                                }
                            }

                            >div.form-group {
                                position: relative;
                                margin-bottom: .5rem;

                                >i {
                                    cursor: pointer;
                                    position: absolute;
                                    top: 47%;
                                    left: 0;
                                    transform: translateY(-50%);
                                    padding: 13px 15px 13px 14px;
                                }

                                >input#searchbar {
                                    max-width: 75%;
                                    padding: 8px 6px 8px 35px;
                                }
                            }

                            >table {
                                width: 100%;
                                text-align: center;
                                border-collapse: collapse;

                                >thead {
                                    background-color: #f1f1f1;

                                    >tr {
                                        >th {
                                            cursor: pointer;
                                            width: 25%;
                                            padding: .4rem;
                                            border: 1px #3d4852 solid;
                                            font-size: 1.1rem;

                                            &.sorted.ascending:after {
                                                content: "  \2191";
                                            }

                                            &.sorted.descending:after {
                                                content: " \2193";
                                            }
                                        }
                                    }
                                }

                                >tbody {
                                    >tr {
                                        &:hover {
                                            background-color: #f1f1f1;
                                        }

                                        >td {
                                            border: 1px #3d4852 solid;
                                            padding: .25rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                /* Animation */
                @keyframes animateTopLogin {
                    from {
                        opacity: 0;
                    }

                    to {
                        opacity: 1;
                    }
                }
            }

            // TITRE
            >h1 {
                text-align: center;
                margin: 0 auto;
                margin-bottom: 1rem;
                border-style: solid;
                border-width: 0 0 2px 0;
                width: fit-content;
                border-image: linear-gradient(90deg, orangered, $csi-orange, yellow) 1;
            }
            // PROFIL
            >article.card-body {

                >h2 {
                    background-color: #ccc;
                    color: #444;
                    padding: 18px;
                    width: 100%;
                    font-size: 1.75rem;
                    border: none;
                    text-align: center;
                    outline: none;
                    box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
                }

                // BODY
                >div {
                    padding: 0.75rem 1.25rem;
                    background-color: #f7f7f7;
                    // border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                    box-shadow: 0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%);

                    // Msg Newsletter
                    >div.alert-success {
                        cursor: pointer;
                        opacity: 1;
                        transition: opacity 0.475s;

                        >ul {
                            list-style: none;
                            margin: 0 auto;
                            padding: 0;
                            text-align: center;
                        }
                    }

                    // FORMULAIRES UPDATES
                    >form {
                        margin-bottom: 2.75rem;

                        >div {

                            // Titres et infos.
                            &.card-header {
                                font-weight: bold;
                                border-top: 1px solid rgba(0, 0, 0, 0.125);

                                >span {
                                    font-size: .85rem;
                                }
                            }

                            // Inputs
                            &.card-body {
                                >div {

                                    &.alert-success,
                                    &.alert-warning,
                                    &.alert-danger {
                                        cursor: pointer;
                                        opacity: 1;
                                        transition: opacity 0.475s;

                                        >ul {
                                            list-style: none;
                                            margin: 0 auto;
                                            padding: 0;
                                            text-align: center;
                                        }
                                    }
                                }

                                >fieldset {

                                    >div.form-group {

                                        // Icon show/hidden pwd
                                        >i {
                                            float: right;
                                            cursor: pointer;
                                            margin-top: -27px;
                                            margin-right: 10px;
                                            color: #212529a1;

                                            &:hover {
                                                color: #212529;
                                            }
                                        }
                                    }

                                    &:last-of-type {
                                        >div.form-group {
                                            &:last-of-type {
                                                margin-bottom: 0;
                                            }
                                        }
                                    }
                                }
                            }

                            &.card-footer {
                                display: flex;
                                flex-direction: row-reverse;
                                border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                                border-top: none;

                                >a,
                                button {
                                    margin-top: .5rem;
                                }

                                &::after {
                                    content: none;
                                }
                            }
                        }
                    }

                    // AUTRES
                    >div {
                        >div {

                            // Titres et infos.
                            &.card-header {
                                font-weight: bold;
                                border-top: 1px solid rgba(0, 0, 0, 0.125);

                                >span {
                                    font-size: .85rem;
                                }

                                // Boutons
                                >div.card-footer {
                                    margin-top: 2rem;
                                    display: flex;
                                    flex-flow: row wrap;
                                    justify-content: space-evenly;
                                    background-color: transparent;

                                    >form {
                                        margin-top: .5rem;
                                    }

                                    button {
                                        font-family: "Open Sans";
                                    }

                                    >button[type=button] {
                                        margin-top: .5rem;
                                    }

                                    &::after {
                                        content: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


/* ------------- */
/* MEDIA QUERIES */
/* ------------- */

@include media-breakpoint-up(sm) {
    body {}
}

@include media-breakpoint-up(md) {
    body {}
}

@include media-breakpoint-up(lg) {
    body {}
}

@include media-breakpoint-up(xl) {
    body {}
}

/* ------ */
/* HEADER */
/* ------ */

header {
    >nav.navbar {
        display: flex !important;
        flex-flow: row wrap;
        box-shadow: 0 1px 4px darkgrey;

        /* LOGO */
        >a.navbar-brand {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            width: fit-content;
            margin-left: 0 !important;

            >p {
                height: fit-content;
                font-size: .9rem;
                margin: 0;
                border-bottom: 1px $csi-orange solid;
            }
        }

        /* HAMBURGER */
        >button[type=button].navbar-toggler {
            display: block;
            margin-left: auto;
        }

        /* NAV */
        >div.navbar-collapse {
            flex-direction: column;
            flex-grow: unset;
            text-align: end;
            margin-top: 1.25rem;

            >ul.navbar-nav {
                text-shadow: none;

                // Nav classique
                &:first-of-type {
                    >li {
                        width: fit-content;
                        margin-left: auto !important;
                        margin: 0 auto;
                        color: rgb(85, 85, 85);
                        display: inline-block;
                        text-decoration: none;
                        background-image: linear-gradient(to right, $csi-orange, $csi-orange);
                        background-position: bottom left;
                        background-repeat: no-repeat;
                        background-size: 0rem 2px;
                        transition: background-size .5s ease;

                        &:hover {
                            background-size: 100% 2px;
                        }

                        >a {
                            >i {
                                color: rgb(85, 85, 85);

                                &.fa-sign-out-alt {
                                    font-size: .85rem;
                                }
                            }
                        }

                        &:hover {
                            >a {
                                >i {
                                    color: $csi-orange;
                                }
                            }
                        }
                    }

                    // Current page
                    >li.active {
                        &:hover {
                            >a {
                                >i {
                                    color: $csi-orange !important;
                                }
                            }
                        }
                    }
                }

                // Logout
                &:nth-of-type(2) {}
            }
        }
    }
}


/* ------------- */
/* MEDIA QUERIES */
/* ------------- */

@include media-breakpoint-down(xs) {
    header {
        >nav.navbar {

            /* LOGO */
            >a.navbar-brand {
                >p {
                    font-size: .75rem;
                }

                >img {
                    width: 3.25rem;
                }
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    header {
        >nav.navbar {

            /* LOGO */
            >a.navbar-brand {
                >img {
                    width: 3.5rem;
                }
            }
        }
    }
}

@include media-breakpoint-up(md) {
    header {
        >nav.navbar {

            /* LOGO */
            >a.navbar-brand {
                >img {
                    width: 3.75rem;
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    header {
        >nav.navbar {
            // display: block !important;

            /* LOGO */
            >a.navbar-brand {
                // margin-left: auto !important;

                >img {
                    width: 4rem;
                }
            }

            /* HAMBURGER */
            >button[type=button].navbar-toggler {
                display: none;
                margin-left: auto !important;
            }

            /* MENU */
            >div.navbar-collapse {
                justify-content: center;
                text-align: left;
            }
        }
    }
}

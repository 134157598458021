/* ------ */
/* FOOTER */
/* ------ */

footer {
    box-shadow: 0 1px 4px darkgrey;

    >div.container {
        >div.row {

            /* LOGO - PLAN DU SITE - COORDONNÉES */
            &:first-of-type {
                justify-content: center;

                >article {
                    text-align: center;

                    // LOGO
                    &:first-of-type {
                        >a {
                            >img {
                                width: 8rem;
                            }
                        }
                    }

                    // PLAN DU SITE
                    &:nth-of-type(2) {
                        >h5 {
                            color: rgba(0, 0, 0, .7);
                        }

                        >ul.list-unstyled {
                            font-size: .95rem;

                            >li {
                                text-shadow: none;

                                >a {
                                    color: rgba(0, 0, 0, .5);

                                    &:hover {
                                        color: rgba(0, 0, 0, 0.7);
                                    }
                                }
                            }
                        }
                    }

                    // COORDONNÉES
                    &:last-of-type {
                        >h5 {
                            color: rgba(0, 0, 0, .7);
                        }

                        >ul.list-unstyled {
                            font-size: .95rem;

                            >li {
                                display: flex;
                                flex-flow: row-reverse nowrap;
                                text-shadow: none;

                                &:not(:last-of-type) {
                                    margin-bottom: .5rem;
                                }

                                >a {
                                    width: 100%;
                                    color: rgba(0, 0, 0, .5);

                                    &:hover {
                                        color: rgba(0, 0, 0, 0.7);
                                    }
                                }

                                >i {
                                    display: none;
                                }

                            }
                        }
                    }
                }
            }

            /* COPYRIGHT */
            &:last-of-type {
                >div {
                    &:first-of-type {
                        color: rgba(0, 0, 0, .5);

                        >p {
                            font-size: .95rem;
                        }
                    }
                }
            }
        }
    }
}


/* ------------- */
/* MEDIA QUERIES */
/* ------------- */

@include media-breakpoint-up(md) {
    footer {
        >div.container {
            >div.row {

                /* LOGO - PLAN DU SITE - COORDONNÉES */
                &:first-of-type {

                    >article {
                        text-align: unset;

                        // COORDONNÉES
                        &:last-of-type {
                            >h5 {
                                color: rgba(0, 0, 0, .7);
                            }

                            >ul.list-unstyled {
                                font-size: .95rem;

                                >li {
                                    display: flex;
                                    flex-flow: row-reverse nowrap;
                                    text-shadow: none;

                                    &:not(:last-of-type) {
                                        margin-bottom: .5rem;
                                    }

                                    >a {
                                        width: 100%;
                                        color: rgba(0, 0, 0, .5);

                                        &:hover {
                                            color: rgba(0, 0, 0, 0.7);

                                            &~i {
                                                color: $csi-orange;
                                            }
                                        }
                                    }

                                    >i {
                                        display: inline;
                                        line-height: 1.6;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

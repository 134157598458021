.slider {
    width: 50%;
    margin: 100px auto;
}

.slick-slide {
    margin: 0px 20px;
}

.slick-slide img {
    width: 100%;
}

.slick-prev:before,
.slick-next:before {
    color: $csi-orange;
}

.slick-slide {
    transition: all ease-in-out .3s;
    // opacity: .2;
}

.slick-active {
    opacity: 1;
}

// .slick-current {
//     opacity: 1;
// }

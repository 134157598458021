/* ---------------- */
/* MENTIONS LÉGALES */
/* ---------------- */

body#mentions-legales {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;

    >main.container {

        >section {
            >h2 {
                text-decoration: underline;
                text-align: center;
                margin-bottom: 1.75rem;
            }

            h3,
            h4 {
                margin-top: 2rem;
                margin-bottom: .75rem;
                text-decoration: underline;
            }

            a {
                font-style: italic;
            }
        }
    }
}


/* ------------- */
/* MEDIA QUERIES */
/* ------------- */

@include media-breakpoint-up(sm) {
    body {}
}

@include media-breakpoint-up(md) {
    body {}
}

@include media-breakpoint-up(lg) {
    body {}
}

@include media-breakpoint-up(xl) {
    body {}
}

/* ----------- */
/* INSCRIPTION */
/* ----------- */

section#inscription {
    z-index: 99999;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;

    >article.modal-content {
        position: relative;
        background-color: #fefefe;
        margin: 5vh auto;
        padding: 0;
        border: 1px solid #888;
        width: 80%;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        animation-name: animateTopInscription;
        animation-duration: 0.4s;

        >div {
            &.modal-header {
                justify-content: center;
                padding: 2px 16px;
                color: white;

                >h3 {
                    text-align: center;
                    font-weight: bold;
                    font-size: 1.5rem;
                    padding-top: 1rem;
                    padding-bottom: .5rem;
                }

                >i {
                    position: absolute;
                    right: 6px;
                    top: 6px;
                    font-size: 1.25rem;
                    color: #dc3545;
                    cursor: pointer;

                    &:hover {
                        font-weight: normal;
                    }
                }
            }

            &.modal-body {
                padding: 2px 16px;

                >div {

                    &.alert-success,
                    &.alert-warning,
                    &.alert-danger {
                        cursor: pointer;
                        opacity: 1;
                        transition: opacity 0.475s;
                        margin-bottom: 0;

                        >ul {
                            list-style: none;
                            margin: 0 auto;
                            padding: 0;
                            text-align: center;
                        }
                    }
                }

                >form {
                    >div {

                        &.card-body {

                            >div {
                                width: 100%;
                                margin: auto;

                                >fieldset {

                                    >div.form-group {
                                        margin-bottom: .75rem;

                                        >label {
                                            margin-bottom: .3rem;
                                        }
                                    }

                                    // Société
                                    &:first-of-type {}

                                    // Civilité, Nom, Prénom
                                    &:nth-of-type(2) {
                                        >div.form-group {
                                            >select {
                                                width: fit-content;
                                                cursor: pointer;
                                            }
                                        }
                                    }

                                    // Tel, Email
                                    &:nth-of-type(3) {}

                                    // Mentions Légales
                                    &:last-of-type {
                                        >div.form-group {
                                            display: flex;
                                            flex-flow: row nowrap;
                                            align-items: center;
                                            margin-top: .5rem;

                                            >input[type=checkbox] {
                                                height: fit-content;
                                                width: fit-content;
                                                cursor: pointer;
                                                transform: scale(1.5);
                                                margin-right: 1rem;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &.card-footer {
                            margin-top: 1rem;
                            display: flex;
                            flex-flow: row wrap;
                            justify-content: center;

                            >a,
                            button {
                                margin-top: .5rem;
                            }

                            &::after {
                                content: none;
                            }
                        }
                    }
                }
            }
        }
    }
}


/* Animation */
@keyframes animateTopInscription {
    from {
        top: -300px;
        opacity: 0;
    }

    to {
        top: 0;
        opacity: 1;
    }
}


/* ------------- */
/* MEDIA QUERIES */
/* ------------- */

@include media-breakpoint-up(sm) {
    body {}
}

@include media-breakpoint-up(md) {
    body {
        section#inscription {
            >article.modal-content {
                >div {

                    &.modal-header {
                        >i {
                            font-size: 1.5rem;
                        }
                    }

                    &.modal-body {
                        >form {
                            >div {
                                &.card-body {
                                    >div {
                                        width: 80%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    body {
        section#inscription {
            >article.modal-content {
                >div {

                    &.modal-body {
                        >form {
                            >div {
                                &.card-body {
                                    >div {
                                        width: 60%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    body {
        section#inscription {
            >article.modal-content {
                >div {

                    &.modal-body {
                        >form {
                            >div {
                                &.card-body {
                                    >div {
                                        width: 50%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* --------------- */
/* TÉLÉCHARGEMENTS */
/* --------------- */

body#telechargements {
    > main.container {
        > section {
            // TYPES FABRICANT
            > h1 {
                text-align: center;
                margin: 0 auto;
                margin-bottom: 1rem;
                border-style: solid;
                border-width: 0 0 2px 0;
                width: fit-content;
                border-image: linear-gradient(90deg, orangered, $csi-orange, yellow) 1;
            }

            // LOGICIELS
            > article.card-body {
                // Boutons Types Fabricants
                > h2 {
                    background-color: #ccc;
                    color: #444;
                    padding: 18px;
                    width: 100%;
                    border: none;
                    text-align: center;
                    outline: none;
                    box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
                }

                // Contenu Types Logiciel
                > div {
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: center;
                    padding: 0 18px;

                    // Fabricants
                    > div {
                        position: relative;
                        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
                        transition: 0.3s;
                        margin: 0.5rem 0.75rem;
                        background-color: #eee;
                        color: #444;
                        cursor: pointer;
                        padding: 18px;
                        border: none;
                        text-align: center;
                        outline: none;

                        &:hover {
                            box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.25);
                        }

                        > img {
                            height: 100%;
                            width: 10rem;
                            object-fit: contain;

                            &.gif {
                                position: absolute;
                                top: 0.25rem;
                                left: 0;
                                height: fit-content;
                                width: 2rem;
                            }
                        }
                    }

                    // Liste Logiciels
                    > section {
                        z-index: 99999;
                        width: 100%;
                        height: 100%;
                        overflow-y: auto;
                        background-color: rgba(0, 0, 0, 0.4);
                        position: fixed;
                        top: 0;
                        left: 0;

                        > article.modal-content {
                            position: relative;
                            background-color: #fefefe;
                            margin: 5vh auto;
                            padding: 0;
                            border: 1px solid #888;
                            width: 80%;
                            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                            animation-name: animateTopLogiciels;
                            animation-duration: 0.4s;

                            > div {
                                &.modal-header {
                                    justify-content: center;
                                    padding: 2px 16px;
                                    color: white;

                                    > h3 {
                                        font-weight: bold;
                                        font-size: 1.75rem;
                                        padding-top: 1rem;
                                        padding-bottom: 0.5rem;
                                    }

                                    > i {
                                        position: absolute;
                                        right: 6px;
                                        top: 6px;
                                        font-size: 1.5rem;
                                        color: #dc3545;
                                        cursor: pointer;

                                        &:hover {
                                            font-weight: normal;
                                        }
                                    }
                                }

                                // Logiciels
                                &.modal-body {
                                    display: flex;
                                    flex-flow: row wrap;
                                    justify-content: center;
                                    padding: 0 18px;
                                    background-color: white;

                                    // Logiciel
                                    > div {
                                        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
                                        transition: 0.3s;
                                        margin: 0.5rem 0.75rem 1rem 0.75rem;
                                        background-color: #eee;
                                        color: #444;
                                        padding: 18px;
                                        border: none;
                                        text-align: center;
                                        outline: none;
                                        width: 30%;

                                        &:hover {
                                            box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.25);
                                        }

                                        > img {
                                            max-width: 115px;
                                            max-height: 100%;
                                        }

                                        > div.container {
                                            padding: 0;

                                            > h4 {
                                                font-size: 1.25rem;
                                                margin-top: 1rem;
                                            }

                                            > p {
                                                justify-content: center;
                                                display: flex;
                                                flex-flow: row wrap;

                                                > img.gif {
                                                    height: fit-content;
                                                    width: 2rem;
                                                }
                                            }

                                            > a.btn.btn-primary {
                                                font-size: small;
                                            }
                                        }
                                    }
                                }
                            }

                            /* Animation */
                            @keyframes animateTopLogiciels {
                                from {
                                    opacity: 0;
                                }

                                to {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ------------- */
/* MEDIA QUERIES */
/* ------------- */

@include media-breakpoint-up(sm) {
    body {
    }
}

@include media-breakpoint-up(md) {
    body {
    }
}

@include media-breakpoint-up(lg) {
    body {
    }
}

@include media-breakpoint-up(xl) {
    body {
    }
}

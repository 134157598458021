/* --------- */
/* PERMANENT */
/* --------- */

html {
    scroll-behavior: smooth;

    * {
        box-sizing: border-box;
        font-family: 'Open Sans';
    }

    >body {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        background-attachment: fixed;

        h1,
        h2,
        h3,
        h4,
        h5,
        p {
            word-wrap: break-word;
            color: rgba(0, 0, 0, .85);
        }

        .hidden {
            display: none !important;
        }

        .active {
            color: rgba(0, 0, 0, .9) !important;

            i {
                color: rgba(0, 0, 0, .9) !important;
            }
        }

        // Force le display des feedbacks
        div.invalid-feedback {
            display: block !important;
        }

        // Alert
        div.alert {
            cursor: pointer;
        }
    }
}

/* ---- grid ---- */

.grid {
  max-width: 1200px;
  text-align: center;
}

/* clearfix */
.grid:after {
  content: '';
  display: block;
  clear: both;
}

/* ---- grid-item ---- */

.grid-sizer,
.grid-item {
  width: 20%;
}

.grid-item {
  height: 120px;
  float: left;
}

.grid-left {
    float: left;
}
.grid-right {
    float: right;
}

.grid-item--width1 { width:  10%; }
.grid-item--width2 { width:  20%; }
.grid-item--width3 { width:  30%; }
.grid-item--width4 { width:  40%; }
.grid-item--width5 { width:  50%; }
.grid-item--width6 { width:  60%; }
.grid-item--width7 { width:  70%; }
.grid-item--width8 { width:  80%; }
.grid-item--width9 { width:  90%; }
.grid-item--width10 { width:  100%; }

.grid-item--height2 { height: 200px; }
.grid-item--height3 { height: 260px; }
.grid-item--height4 { height: 360px; }

@include media-breakpoint-down(md){
    .grid-left {
        float: none;
    }
    .grid-right {
        float: none;
    }
}

@include media-breakpoint-only(xs){
    .grid-left {
        float: none;
    }
    .grid-right {
        float: none;
    }
}

/* --------- */
/* VARIABLES */
/* --------- */

// Bootstrap breakpoints
$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: .9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

// CSI Colors
$csi-orange: #ffa500;
$csi-orange-dark: #e99801;
$csi-blue: #66CCCC;

// Slick
// $slick-font-path: "~slick-carousel/slick/fonts/";
// $slick-loader-path: "~slick-carousel/slick/";

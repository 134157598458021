/* ------- */
/* ACCUEIL */
/* ------- */

body#accueil {

    /* BANNIERE */
    >div.banniere {
        text-align: center;

        >img {
            width: 90%;
        }
    }

    >main.container {
        >section {

            /* PRÉSENTATION */
            >article {
                margin: 0 1rem;
                padding: 1rem;
                box-shadow: 0 4px 4px rgba(0, 0, 0, .25);

                >h1 {
                    text-align: center;
                    margin: 0 auto;
                    margin-bottom: 1rem;
                    border-style: solid;
                    border-width: 0 0 2px 0;
                    width: fit-content;
                    border-image: linear-gradient(90deg, orangered, $csi-orange, yellow) 1;
                }

                >p {
                    text-align: justify;
                }
            }

            /* TEXTES */
            >div.flex {
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
                margin: 0 1rem;

                >article.about {
                    display: flex;
                    flex-direction: column;
                    padding: 1rem;
                    box-shadow: 0 4px 4px rgba(0, 0, 0, .25);

                    &:not(:last-of-type) {
                        width: 47.5%;

                        >p {
                            text-align: justify;
                        }
                    }

                    &:last-of-type {
                        width: 100%;

                        >p {
                            text-align: center;
                        }
                    }

                    >h2 {
                        text-align: center;
                        border-style: solid;
                        margin-left: auto;
                        margin-right: auto;
                        border-width: 0 0 2px 0;
                        width: fit-content;
                        border-image: linear-gradient(90deg, orangered, $csi-orange, yellow) 1;
                    }
                }
            }

            /* IMGS SÉPARATRICES */
            >div.image {
                background-size: cover;
                height: 25rem;
                /* pour éviter les tremblements d'images au chargement // to avoid images shakings on load */
                backface-visibility: hidden;
                border-radius: 25px;

                // BTN CONTACT
                &:last-of-type {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    $thick: 3px;
                    $pad: 0.7em;
                    $extra : calc(#{$pad} * 1.2);

                    a {
                        color: white;
                        padding: $pad $extra;
                        display: inline-block;
                        border: $thick solid transparent;
                        position: relative;
                        font-size: 1.5em;
                        cursor: pointer;
                        letter-spacing: 0.07em;

                        .text {
                            // padding: 0 0.3em;
                            font-family: proxima-nova, monospace;
                            transform: translate3d(0, $pad, 0);
                            display: block;
                            transition: transform 0.4s cubic-bezier(.2, 0, 0, 1) 0.4s;
                            text-shadow: 0 1px 0 black;
                        }

                        &:after {
                            position: absolute;
                            content: '';
                            bottom: -$thick;
                            left: $extra;
                            right: $extra;
                            height: $thick;
                            background: $csi-orange;
                            // z-index: -1;
                            transition:
                                transform 0.8s cubic-bezier(1, 0, .37, 1) 0.2s,
                                right 0.2s cubic-bezier(.04, .48, 0, 1) 0.6s,
                                left 0.4s cubic-bezier(.04, .48, 0, 1) 0.6s;
                            transform-origin: left;
                        }

                    }

                    .line {
                        position: absolute;
                        background: $csi-orange;

                        &.-right,
                        &.-left {
                            width: $thick;
                            bottom: -$thick;
                            top: -$thick;
                            transform: scale3d(1, 0, 1);
                        }

                        &.-top,
                        &.-bottom {
                            height: $thick;
                            left: -$thick;
                            right: -$thick;
                            transform: scale3d(0, 1, 1);
                        }

                        &.-right {
                            right: -$thick;
                            transition: transform 0.1s cubic-bezier(1, 0, .65, 1.01) 0.23s;
                            transform-origin: top;
                        }

                        &.-top {
                            top: -$thick;
                            transition: transform 0.08s linear 0.43s;
                            transform-origin: left;
                        }

                        &.-left {
                            left: -$thick;
                            transition: transform 0.08s linear 0.51s;
                            transform-origin: bottom;
                        }

                        &.-bottom {
                            bottom: -$thick;
                            transition: transform 0.3s cubic-bezier(1, 0, .65, 1.01);
                            transform-origin: right;
                        }
                    }

                    a:hover,
                    a:active {
                        text-decoration: none;

                        .text {
                            transform: translate3d(0, 0, 0);
                            transition: transform 0.6s cubic-bezier(.2, 0, 0, 1) 0.4s;
                            text-shadow: 0 1px 0 $csi-orange;
                        }

                        &:after {
                            transform: scale3d(0, 1, 1);
                            right: -$thick;
                            left: -$thick;
                            transform-origin: right;
                            transition:
                                transform 0.2s cubic-bezier(1, 0, .65, 1.01) 0.17s,
                                right 0.2s cubic-bezier(1, 0, .65, 1.01),
                                left 0s 0.3s;
                        }

                        .line {
                            transform: scale3d(1, 1, 1);

                            &.-right {
                                transition: transform 0.1s cubic-bezier(1, 0, .65, 1.01) 0.2s;
                                transform-origin: bottom;
                            }

                            &.-top {
                                transition: transform 0.08s linear 0.4s;
                                transform-origin: right;
                            }

                            &.-left {
                                transition: transform 0.08s linear 0.48s;
                                transform-origin: top;
                            }

                            &.-bottom {
                                transition: transform 0.5s cubic-bezier(0, .53, .29, 1) 0.56s;
                                transform-origin: left;
                            }
                        }
                    }
                }
            }

            /* TITRE SLIDER */
            >h2 {
                margin: 0 auto;
                text-align: center;
                border-style: solid;
                border-width: 0 0 2px 0;
                width: fit-content;
                border-image: linear-gradient(90deg, orangered, $csi-orange, yellow) 1;
            }

            /* SLIDER */
            >section.autoplay.slider {
                width: 90%;
                margin-top: 2rem;

                >div.slick-list.draggable {
                    >div.slick-track {
                        >div.slick-slide {
                            >div {
                                height: 100px;
                                width: inherit;

                                >figure.element {
                                    height: inherit;
                                    width: inherit !important;
                                    margin: 0;

                                    >img {
                                        height: inherit;
                                    }

                                    >figcaption {
                                        width: inherit;
                                        display: none;
                                        align-items: center;
                                        justify-content: center;
                                        position: absolute;
                                        top: 0;
                                        height: inherit;
                                        opacity: 0.9;
                                        background-color: lightgrey;
                                        text-align: center;

                                        >a {
                                            height: 100%;
                                            width: 100%;
                                            justify-content: center;
                                            align-items: center;
                                            display: flex;
                                            font-weight: bold;
                                            letter-spacing: .15rem;
                                            cursor: pointer;
                                        }

                                        h3 {
                                            font-size: 1.3rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


/* ------------- */
/* MEDIA QUERIES */
/* ------------- */

@include media-breakpoint-down(xs) {
    body#accueil {
        h1 {
            font-size: 1.3rem;
        }

        h2 {
            font-size: 1.05rem;
        }
    }
}

@include media-breakpoint-up(sm) {
    body#accueil {
        h1 {
            font-size: 1.6rem;
        }

        h2 {
            font-size: 1.35rem;
        }
    }
}

@include media-breakpoint-up(md) {
    body#accueil {
        h1 {
            font-size: 1.75rem;
        }

        h2 {
            font-size: 1.5rem;
        }
    }
}

@include media-breakpoint-up(lg) {
    body#accueil {
        h1 {
            font-size: 2rem;
        }

        h2 {
            font-size: 1.75rem;
        }
    }
}

@include media-breakpoint-up(xl) {
    body#accueil {
        h1 {
            font-size: 2.25rem;
        }

        h2 {
            font-size: 2rem;
        }
    }
}

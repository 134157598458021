/* ------- */
/* CONTACT */
/* ------- */

body#contact {

    >main.container {
        >section {

            // TITRE
            >h1 {
                text-align: center;
                margin: 0 auto;
                margin-top: 6px;
                margin-bottom: 1rem;
                border-style: solid;
                border-width: 0 0 2px 0;
                width: fit-content;
                border-image: linear-gradient(90deg, orangered, $csi-orange, yellow) 1;
            }

            >article {
                margin: 0 1rem;
                padding: 1rem;

                >div.card-body {
                    box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
                    background-color: #f7f7f7;;

                    >div {

                        &.alert-success,
                        &.alert-warning,
                        &.alert-danger {
                            cursor: pointer;
                            opacity: 1;
                            transition: opacity 0.475s;
                            margin-bottom: 0;

                            >ul {
                                list-style: none;
                                margin: 0 auto;
                                padding: 0;
                                text-align: center;
                            }
                        }
                    }

                    // FORMULAIRE
                    >form {
                        * {
                            -webkit-box-sizing: border-box;
                            -moz-box-sizing: border-box;
                            box-sizing: border-box;
                        }
                        div.row {
                            margin: 0;
                        }
                        span.placeholder,
                        span.placeholder-select {
                            small {
                                font-size: .6rem !important;
                                position: absolute;
                                bottom: 25%;
                            }
                        }
                        a:hover {
                            text-decoration: none;
                        }
                        :focus {
                            outline: none;
                        }
                        textarea {
                            resize: none;
                        }
                        .form-group {
                            position: relative;
                            display: block;
                            margin-bottom: 48px;
                            span {
                                font-size: 15px;
                                position: absolute;
                                padding-left: 0.75rem;
                                top: 11px;
                                transition: all 0.2s ease;
                                transform-origin: 0 0;
                                cursor: text;
                                &.rules {
                                    display: none;
                                }
                            }
                        }
                        .form-control {
                            border: none;
                            border-top-left-radius: 0;
                            border-top-left-radius: 0;
                            display: block;
                            width: 100%;
                            height: 43px;
                            font-size: 15px;
                            background: none;
                            &:not(#f_civilite) {
                                border-left: 1px solid $csi-orange;
                                border-bottom: 1px solid $csi-orange;
                            }
                            &:focus,
                            &:valid,
                            &:not([value=""]) {
                                & + span.placeholder {
                                    padding-left: 0;
                                    transform: translateY(-33px) scale(0.8);
                                }
                            }
                            &:focus {
                                border-left: 1px solid #fff !important;
                                border-bottom: 1px solid #fff !important;
                                box-shadow: 0 0 0 0.15rem $csi-blue;
                            }
                            &:valid {
                                border-left: 1px solid $csi-blue !important;
                                border-bottom: 1px solid $csi-blue !important;
                                & ~ span.rules {
                                    display: none !important;
                                }
                            }
                            &#f_civilite {
                                border: 1px solid $csi-orange;
                                &:valid {
                                    border: 1px solid $csi-blue !important;
                                }
                                &:focus {
                                    border: 1px solid #fff !important;
                                }
                                &:focus,
                                &:valid {
                                    & + span.placeholder-select {
                                        padding-left: 0;
                                        transform: translateY(-33px) scale(0.8);
                                    }
                                }
                            }
                            &:not(:empty),
                            &:not([value=""]) {
                                & ~ span.rules {
                                    display: block;
                                    padding-left: 0;
                                    transform: translateY(33px) scale(0.8);
                                }
                            }
                        }
                        legend {
                            text-decoration: underline;
                        }
                        label {
                            padding: 0;
                        }
                        select#f_civilite {
                            cursor: pointer;
                            width: 6rem;
                        }
                        textarea#f_message {
                            padding-top: 10px;
                            padding-bottom: 10px;
                            height: 200px;
                        }
                        >div.card-footer {
                            margin-top: 1.25rem;
                            display: flex;
                            flex-flow: row-reverse;
                        }
                    }
                }
            }
        }
    }
}


/* ------------- */
/* MEDIA QUERIES */
/* ------------- */

@include media-breakpoint-up(sm) {
    body {}
}

@include media-breakpoint-up(md) {
    body {}
}

@include media-breakpoint-up(lg) {
    body {}
}

@include media-breakpoint-up(xl) {
    body {}
}

/* ----- */
/* POPUP */
/* ----- */

section#popup {
    z-index: 99999;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;

    >article.modal-content {
        position: relative;
        background-color: #fefefe;
        margin: 5vh auto;
        padding: 0;
        border: 1px solid #888;
        width: 80%;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        animation-name: animateTopPopup;
        animation-duration: 0.4s;

        >div {
            &.modal-header {
                color: white;
                box-shadow: none;
                border: none;

                >i {
                    position: absolute;
                    right: 6px;
                    top: 6px;
                    font-size: 1.25rem;
                    color: #dc3545;
                    cursor: pointer;

                    &:hover {
                        font-weight: normal;
                    }
                }
            }

            &.modal-body {
                padding: 2rem;

                >div.alert-success {
                    cursor: pointer;
                    opacity: 1;
                    transition: opacity 0.475s;
                    margin-bottom: 0;

                    >ul {
                        list-style: none;
                        margin: 0 auto;
                        padding: 0;
                        text-align: center;
                    }
                }

            }
        }
    }
}


/* Animation */
@keyframes animateTopPopup {
    from {
        top: -300px;
        opacity: 0;
    }

    to {
        top: 0;
        opacity: 1;
    }
}

/* ------------ */
/* FRONT-OFFICE */
/* ------------ */

/* TOOLS */
/* ----- */

// Variables :
@import '_variables';

// CSS Permanent :
@import 'permanent';

// Fonts :
@import url('https://fonts.googleapis.com/css2?family=Open+Sans');

// Bootstrap (layout général) :
@import '~bootstrap/dist/css/bootstrap.css';
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

// Popup (jquery-confirm) :
@import '~jquery-confirm/css/jquery-confirm.css';


/* COMPONENTS */
/* ---------- */

// Header :
@import 'components/header';

// Footer :
@import 'components/footer';

// Slick (layout slider) :
@import '~slick-carousel/slick/slick.scss';
@import '~slick-carousel/slick/slick-theme.scss';
@import 'components/slick';

// Masonry (layout d'images) :
@import 'components/masonry';

// Modal Popup :
@import 'components/popup';

// Modal Reset Mdp :
@import 'components/reset-mdp';

// Modal Login :
@import 'components/login';

// Modal Inscription :
@import 'components/inscription';


/* PAGES */
/* ----- */

// Accueil :
@import 'pages/accueil';

// Téléchargements :
@import 'pages/telechargements';

// Compte :
@import 'pages/compte';

// Contact :
@import 'pages/contact';

// Mentions Légales :
@import 'pages/mentions-legales';
